import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ApexCharts from 'react-apexcharts'

export const REMINDER_STATUS = {
  SNOOZED: '1',
  MISSED: '2',
  ACKNOWLEDGED: '3',
  NEW: '4',
  REMOVED: 'deleted',
}

const CurrentYearComponent = ({ isShow, tab, detailsList }) => {
  const [series, setSeries] = useState([])
  const [labels, setLabels] = useState([])
  const [selectData, setSelectData] = useState()
  const [year, setYear] = useState(1)

  const getValueReminders = (reminders = [], selectDefault) => {
    let labelsList = []
    let seriesList = []
    reminders.map((item) => {
      labelsList.push(item?.name ?? '')
      let start_time = moment(item?.start_time, 'DD/MM/YYYY HH:mm').utcOffset(
        480
      )
      let valid_from = moment(
        selectDefault?.valid_from,
        'DD/MM/YYYY'
      ).utcOffset(480)
      let totalDiff = start_time.diff(valid_from, 'minutes')
      let nowDiff = moment()
        .utcOffset(480)
        .diff(valid_from, 'minutes')
      let percent = (nowDiff / totalDiff) * 100
      seriesList.push(percent > 100 ? 100 : percent)
    })
    setLabels(labelsList)
    setSeries(seriesList)
  }

  useEffect(() => {
    let selectDefault = detailsList?.find((i, index) => {
      let isBetween = moment().isBetween(i.valid_from, i.valid_to)
      if (isBetween) {
        setYear(index + 1)
      }
      return isBetween
    })
    if (isShow && selectDefault) {
      setSelectData(selectDefault)
      getValueReminders(selectDefault?.reminders, selectDefault)
    }
  }, [detailsList, tab])
  let state2 = {
    series: series,
    options: {
      labels: labels,
      chart: {
        type: 'radialBar',
        height: 450,
      },
      colors: [
        '#FF443C',
        '#FF8800',
        '#FFCA41',
        '#A1DC67',
        '#02C038',
        '#29B9E6',
        '#338FEB',
        '#003AA5',
        '#9747FF',
        '#FF0492',
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        radialBar: {
          borderRadius: 30,
          dataLabels: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
        markers: { shape: 'circle' },
        fontSize: '14px',
        fontWeight: 400,
        labels: {
          colors: '#1F263E',
          useSeriesColors: false,
        },
      },
      stroke: {
        lineCap: 'round',
      },
    },
  }
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: '-35px',
          right: '0px',
        }}
        className='rowy-center-end'
      >
        <div
          style={{
            background: '#F4F7FE',
            border: '1px solid #1F263E',
            padding: '6px 12px',
            borderRadius: '6px',
          }}
          className='f-medium_20'
        >
          (Y{year}) {selectData?.valid_from} - {selectData?.valid_to}
        </div>
      </div>
      <div className='apex-charts-row'>
        <ApexCharts
          options={state2.options}
          series={state2.series}
          type='radialBar'
          height={450}
        />
      </div>
    </>
  )
}

export default CurrentYearComponent
