import { Col, Form, Row, Tabs, Tag } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import CurrentYearComponent from './CurrentYearComponent'
import SummaryComponent from './SummaryComponent'
import UploadFileComponent from '../../../../common/antd/component/upload/UploadFileComponent'
import makeRequest from '../../../../libs/request'
import { showErrorMessage } from '../../../../actions/notification'

export const DOCUMENT_TYPE = {
  CERTIFICATION: 'certification',
  LICENSE: 'license',
  DOCUMENT: 'document',
}

const OverviewItemComponent = ({
  value,
  isHistory,
  overview,
  showDefault = false,
}) => {
  const [form] = Form.useForm()
  const [show, setShow] = useState(showDefault)
  const [tab, setTab] = useState('summary')
  const [standard, setStandardName] = useState()
  const [certification, setCertification] = useState()
  const [selectData, setSelectData] = useState()
  const [selectCertiLincen, setSelectCertiLincen] = useState({})
  const [valueDoc, setValueDoc] = useState([])
  const [detailsList, setDetailsList] = useState([])
  const getCertification = async () => {
    let certificationListData = {}
    await makeRequest('get', `icpa/certifications/get-info/${value?._id}`)
      .then(({ data }) => {
        if (data.signal) {
          let itemList = data.data
          certificationListData = itemList
        } else {
          certificationListData = {}
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    setCertification(certificationListData ?? {})
    setStandardName(certificationListData?.standard ?? '')
    let detailsList = certificationListData?.details ?? []
    let selectDefault = detailsList?.find((i) =>
      moment().isBetween(i.valid_from, i.valid_to)
    )
    setDetailsList(detailsList)
    selectDefault = selectDefault
      ? selectDefault
      : detailsList?.length > 0
        ? detailsList[detailsList?.length - 1]
        : {}
    setSelectData(selectDefault)
    if (selectDefault?._id) {
      let certificationData = {}
      await makeRequest(
        'get',
        `icpa/certifications/details/get-one/${selectDefault?._id}`
      )
        .then(({ data }) => {
          if (data.signal) {
            let itemList = data.data
            certificationData = itemList
          } else {
            certificationData = {}
            return showErrorMessage(data.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      if (certificationData) {
        let certification = await certificationData?.documents.find(
          (i) => i.type === DOCUMENT_TYPE.CERTIFICATION
        )
        let license = await certificationData?.documents.find(
          (i) => i.type === DOCUMENT_TYPE.LICENSE
        )
        setSelectCertiLincen({
          upload_license: license,
          upload_certification: certification,
          certification_code: certificationData?.certification_code ?? '',
          license: certificationData?.license ?? '',
        })
      }

      let document = []
      await makeRequest(
        'get',
        `icpa/certifications/${selectDefault?._id}/documents/get-all`
      ).then(({ data }) => {
        if (data.signal) {
          let itemList = data.data
          document = itemList
        } else {
          document = []
          return showErrorMessage(data.message)
        }
      })
      let newListDocument = document ? document.filter((i) => i.url) : []
      setValueDoc(newListDocument ?? [])
    }
  }

  const changeSelect = async (year) => {
    let selectDefault = detailsList?.find((i) =>
      moment(year, 'YYYY').isSame(moment(i.valid_from, 'DD/MM/YYYY'), 'year')
    )
    if (selectDefault?._id) {
      setSelectData(selectDefault)
      let certificationData = {}
      await makeRequest(
        'get',
        `icpa/certifications/details/get-one/${selectDefault?._id}`
      )
        .then(({ data }) => {
          if (data.signal) {
            let itemList = data.data
            certificationData = itemList
          } else {
            certificationData = {}
            return showErrorMessage(data.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      if (certificationData) {
        let certification = await certificationData?.documents.find(
          (i) => i.type === DOCUMENT_TYPE.CERTIFICATION
        )
        let license = await certificationData?.documents.find(
          (i) => i.type === DOCUMENT_TYPE.LICENSE
        )
        setSelectCertiLincen({
          upload_license: license,
          upload_certification: certification,
          certification_code: certificationData?.certification_code ?? '',
          license: certificationData?.license ?? '',
        })
      }
      if (certificationData) {
        let certification = await certificationData?.documents.find(
          (i) => i.type === DOCUMENT_TYPE.CERTIFICATION
        )
        let license = await certificationData?.documents.find(
          (i) => i.type === DOCUMENT_TYPE.LICENSE
        )
        setSelectCertiLincen({
          upload_license: license,
          upload_certification: certification,
          certification_code: certificationData?.certification_code ?? '',
          license: certificationData?.license ?? '',
        })
      }

      let document = []
      await makeRequest(
        'get',
        `icpa/certifications/${selectDefault?._id}/documents/get-all`
      ).then(({ data }) => {
        if (data.signal) {
          let itemList = data.data
          document = itemList
        } else {
          document = []
          return showErrorMessage(data.message)
        }
      })
      let newListDocument = document ? document.filter((i) => i.url) : []
      setValueDoc(newListDocument ?? [])
    }
  }

  useEffect(() => {
    setShow(showDefault)
  }, [value])

  useEffect(() => {
    setStandardName(value?.standard)
    if (show) {
      getCertification()
    }
  }, [show, value])

  let renderStatus = (status) => {
    if (status === 'active') {
      return (
        <Tag bordered={false} className='ml-8 tag-marking-progress-2'>
          Active
        </Tag>
      )
    } else if (status === 'inactive') {
      return (
        <Tag bordered={false} className='ml-8 tag-new'>
          Inactive
        </Tag>
      )
    } else if (status === 'complete') {
      return (
        <Tag bordered={false} className='ml-8 tag-marking-completed-2'>
          Complete
        </Tag>
      )
    } else if (status === 'incomplete') {
      return (
        <Tag bordered={false} className='ml-8 tag-new'>
          Incomplete
        </Tag>
      )
    } else if (status === 'Archived') {
      return (
        <Tag bordered={false} className='ml-8 tag-marking-completed-2'>
          Complete
        </Tag>
      )
    } else
      return (
        <Tag bordered={false} className='ml-8 tag-draft'>
          {status}
        </Tag>
      )
  }
  return (
    <Form
      form={form}
      name='certificationCode'
      autoComplete='off'
      layout='vertical'
      requiredMark={false}
      className='form-item-customer'
    >
      <div
        style={{
          borderRadius: '16px',
          border: '1px solid #C0C7D8',
          padding: '24px 0px 24px 0px',
          background: '#FFFFFF',
        }}
      >
        <div className='pl-24 pr-24'>
          <div className='row-sb '>
            <div
              style={{
                fontSize: '24px',
                fontWeight: '600',
                color: '#1B2559',
                position: 'relative',
                cursor: 'pointer',
                width: '100%',
              }}
              className='mb-8 rowy-center '
              onClick={() => setShow(!show)}
            >
              {standard ? <span className='row-center'>{standard}</span> : ''}

              {overview && renderStatus(value?.status)}
              {!overview && renderStatus(value?.status_of_full_cycle)}
            </div>
            <div
              className='row-center'
              style={{
                width: '36px',
                height: '36px',
                borderRadius: '6px',
                backgroundColor: '#9747FF',
                color: '#FFFFFF',
                cursor: 'pointer',
              }}
              onClick={() => setShow(!show)}
            >
              <div
                className='row-center'
                style={{ transform: show ? 'rotate(0deg)' : 'rotate(180deg)' }}
              >
                <svg
                  width='14'
                  height='8'
                  viewBox='0 0 14 8'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.99998 2.90905L1.55556 8L0 6.54547L6.99998 0L14 6.54547L12.4445 8L6.99998 2.90905Z'
                    fill='white'
                  />
                </svg>
              </div>
            </div>
          </div>
          {show && (
            <div
              style={{
                position: 'relative',
              }}
            >
              {isHistory ? (
                <SummaryComponent
                  selectData={selectData}
                  certification={certification}
                  changeSelect={changeSelect}
                />
              ) : (
                <Tabs
                  className='ant-tabs-nav-list-3'
                  onChange={(value) => {
                    setTab(value)
                  }}
                >
                  <Tabs.TabPane tab='Summary' key='summary'>
                    <SummaryComponent
                      selectData={selectData}
                      certification={certification}
                      changeSelect={changeSelect}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab='Current Year' key='current-year'>
                    <CurrentYearComponent tab={tab} isShow={tab === 'current-year'} selectData={selectData} detailsList={detailsList} />
                  </Tabs.TabPane>
                </Tabs>
              )}
              {(selectCertiLincen?.license ||
                selectCertiLincen?.upload_license ||
                selectCertiLincen?.license ||
                selectCertiLincen?.upload_license) &&
                tab === 'current-year' && (
                  <div className='apex-charts-row p-16'>
                    <Row gutter={24} className='mt-8'>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        {(selectCertiLincen?.license ||
                          selectCertiLincen?.upload_license) && (
                            <>
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  color: '#1F263E',
                                }}
                                className='mb-8 rowy-center '
                              >
                                Certification:{' '}
                                {selectCertiLincen?.certification_code}
                              </div>
                              {selectCertiLincen?.upload_certification && (
                                <UploadFileComponent
                                  value={selectCertiLincen?.upload_certification}
                                  label={`Certification`}
                                  type={'certification'}
                                  isDeleteFile={true}
                                  disabled={true}
                                  isReview={true}
                                />
                              )}
                            </>
                          )}
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        {(selectCertiLincen?.license ||
                          selectCertiLincen?.upload_license) && (
                            <>
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  color: '#1F263E',
                                }}
                                className='mb-8 rowy-center '
                              >
                                License: {selectCertiLincen?.license}
                              </div>
                              {selectCertiLincen?.upload_license && (
                                <UploadFileComponent
                                  value={selectCertiLincen?.upload_license}
                                  label={`License`}
                                  type={'license'}
                                  isDeleteFile={true}
                                  disabled={true}
                                  isReview={true}
                                />
                              )}
                            </>
                          )}
                      </Col>
                    </Row>
                  </div>
                )}
              {valueDoc?.length > 0 && tab === 'summary' && (
                <div className='apex-charts-row p-16'>
                  <div className='row-sb '>
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        color: '#1F263E',
                      }}
                      className='mb-8 rowy-center '
                    >
                      Document
                    </div>
                    {selectData?.valid_from && (
                      <div
                        style={{
                          background: '#F4F7FE',
                          border: '1px solid #1F263E',
                          padding: '6px 12px',
                          borderRadius: '6px',
                        }}
                        className='f-medium_20'
                      >
                        {moment(selectData?.valid_from, 'DD/MM/YYYY').year()}
                      </div>
                    )}
                  </div>
                  <Row gutter={24} className='mt-8'>
                    {valueDoc.map((i, index) => {
                      return (
                        <Col
                          key={index}
                          xs={24}
                          sm={24}
                          md={12}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          <UploadFileComponent
                            value={i}
                            label={`document`}
                            type={'document'}
                            isDeleteFile={true}
                            disabled={true}
                            isReview={true}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}

export default OverviewItemComponent
